'use client';

import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import React from 'react';
import { MouseParallax } from 'react-just-parallax';
import { useRouter } from 'next/navigation';
import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import { theme } from '../../store/theme';

import Button, { ButtonVariants } from '../Button';
import Ellipse, { EllipseVariants } from '../Ellipse';
import Logo from '../Logo';

import styles from './Error.module.scss';

const Page404 = () => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const appTheme = useAtomValue(theme);
  const router = useRouter();

  return (
    <>
      <div
        aria-hidden='true'
        className={styles.background}
      >
        {typeof document !== 'undefined' && (
          <>
            <MouseParallax
              isAbsolutelyPositioned
              strength={0.05}
            >
              <Ellipse
                variant={EllipseVariants.DRAW}
                size={deviceSize === ScreenSize.MOBILE ? 572 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 623 : 750}
                className={classNames(styles.parallax, styles.parallax__draw)}
              />
            </MouseParallax>
            <MouseParallax
              isAbsolutelyPositioned
              strength={0.12}
            >
              <Ellipse
                variant={EllipseVariants.NOISE}
                size={89}
                className={classNames(styles.parallax, styles.parallax__noise)}
              />
            </MouseParallax>
            <MouseParallax
              isAbsolutelyPositioned
              strength={0.08}
            >
              <Ellipse
                variant={EllipseVariants.BORDER}
                size={deviceSize < ScreenSize.TABLET_PORTRAIT ? 23 : 25}
                className={classNames(styles.parallax, styles.parallax__border)}
              />
            </MouseParallax>
            <MouseParallax
              isAbsolutelyPositioned
              strength={0.1}
            >
              <Ellipse
                variant={EllipseVariants.GRADIENT}
                size={38}
                className={classNames(styles.parallax, styles.parallax__gradient)}
              />
            </MouseParallax>
            <MouseParallax
              isAbsolutelyPositioned
              strength={0.1}
            >
              <span className={classNames(styles.parallax, styles.parallax__four, styles.parallax__four_top)}>
                <img
                  src={`/media/4-${appTheme}.svg`}
                  alt='four'
                />
              </span>
            </MouseParallax>
            <MouseParallax
              isAbsolutelyPositioned
              strength={0.1}
            >
              <span className={classNames(styles.parallax, styles.parallax__four, styles.parallax__four_bottom)}>
                <img
                  src={`/media/4-${appTheme}.svg`}
                  alt='four'
                />
              </span>
            </MouseParallax>
            <span className={classNames(styles.ellipse, styles.ellipse__top)} />
            <span className={classNames(styles.ellipse, styles.ellipse__bottom)} />
          </>
        )}
      </div>
      <section className={styles.wrapper}>
        <Link
          href='/'
          aria-label='Get back to main page'
          className={styles.logo}
          prefetch
        >
          <Logo
            width={119}
            height={32}
          />
        </Link>
        <div className={styles.content}>
          <h2 className={styles.title}>Houston, we have a problem!</h2>
          <p className={styles.text}>Take a moment to pause, recalibrate your coordinates, and set forth on a journey of discovery.</p>
          <Button
            onClick={() => router.push('/')}
            className={styles.btn}
            variant={ButtonVariants.OUTLINED}
            icon={(
              <svg className={styles.btn__icon}>
                <use
                  xlinkHref='/media/icon-nest.svg#iconNest'
                  href='/media/icon-nest.svg#iconNest'
                />
              </svg>
            )}
            iconSize={{
              width: 24,
              height: 24,
            }}
          >
            Back to home
          </Button>
        </div>
      </section>
    </>
  );
};

export default Page404;
